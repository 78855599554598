<template>
  <div
    class="text-center py-5 my-2 d-flex flex-column justify-content-center align-items-center"
  >
    <span>{{ message }}</span>
    <BButton
      variant="primary"
      class="mt-2"
      @click.prevent="$emit('action-clicked')"
    >
      {{ actionLabel }}
    </BButton>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  props: {
    message: {
      type: String,
      default: 'An error occured while requesting data',
    },
    actionLabel: {
      type: String,
      default: 'Try Again',
    },
  },
}
</script>
